<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="pcqwnpk72a"
        d="M6.667 2.667V0H0v12h13.333V2.667H6.667zm-1.334 8h-4V9.333h4v1.334zm0-2.667h-4V6.667h4V8zm0-2.667h-4V4h4v1.333zm0-2.666h-4V1.333h4v1.334zm6.667 8H6.667V4H12v6.667zm-1.333-5.334H8v1.334h2.667V5.333zm0 2.667H8v1.333h2.667V8z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-137 -593) translate(105 516) translate(32 70) translate(0 7)"
              />
              <path
                d="M2 2L14 2 14 14 2 14z"
                transform="translate(-137 -593) translate(105 516) translate(32 70) translate(0 7)"
              />
              <g
                transform="translate(-137 -593) translate(105 516) translate(32 70) translate(0 7) translate(1.333 2)"
              >
                <mask id="1fhjqdiefb" fill="#fff">
                  <use xlink:href="#pcqwnpk72a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#pcqwnpk72a" />
                <g fill="#90A4AE" mask="url(#1fhjqdiefb)">
                  <path d="M0 0H16V16H0z" transform="translate(-1.333 -2)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
