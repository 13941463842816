import {
  AssessmentSearchItem,
  AssessmentStatus,
} from "./../../../api/interfaces/SubjectsInterfaces";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";

import {
  AssessmentItem,
  SubjectProfile,
} from "../../../api/interfaces/SubjectsInterfaces";

import CalendarTodayIcon from "@/assets/icons/cards/CalendarToday.vue";
import PhoneIcon from "@/assets/icons/cards/Phone.vue";
import UserIcon from "@/assets/icons/cards/User.vue";
import WorkOutlineIcon from "@/assets/icons/cards/WorkOutline.vue";
import CorporateFareIcon from "@/assets/icons/cards/CorporateFare.vue";
import EditIcon from "@/assets/icons/Edit.vue";
import DeleteIcon from "@/assets/icons/Delete.vue";

import { useRoute } from "vue-router";
import { useStore } from "@/store/store";

import format from "date-fns/format";

import { getAssessmentsSearch } from "@/api/SubjectsApi";
import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";
import DropdownFilter from "@/components/DropdownFilter/DropdownFilter.vue";
import isEqual from "lodash-es/isEqual";
import router from "@/router";

export default defineComponent({
  name: "ViewSubject",
  props: { subject: Object as () => SubjectProfile },
  components: {
    CalendarTodayIcon,
    PhoneIcon,
    UserIcon,
    WorkOutlineIcon,
    CorporateFareIcon,
    EditIcon,
    DeleteIcon,
    DropdownFilter,
  },
  setup() {
    const route = useRoute();

    const subjectId = route.params.id as string;
    const siteId = route.params.siteId as string;

    const firstAssessment = route.params.assessmentId as string;

    const state = ref({
      assessments: (null as any) as AssessmentSearchItem[],
      currentAssessment: "",
      assessmentsObject: {
        items: [],
        values: "",
      } as FilterObject,
    });

    watch(
      () => state.value.assessmentsObject.values,
      (newValue: any, oldValue: any) => {
        if (!isEqual(newValue, oldValue)) {
          state.value.currentAssessment = newValue;

          if (
            route.params.assessmentId != "" &&
            newValue &&
            route.params.assessmentId !== newValue
          ) {
            router.push({
              name: "View HiPAL Pro",
              params: {
                id: subjectId,
                siteId,
                assessmentId: state.value.currentAssessment,
              },
            });
          }
        }
      }
    );

    onBeforeMount(async () => {
      const result = await getAssessmentsSearch(
        {
          paging: {
            page_number: 1,
            amount_per_page: 99999,
          },
          sorting: undefined,
        },
        subjectId,
        siteId
      );
      state.value.assessments = result.entities.filter(
        (a) => a.status === AssessmentStatus.Completed
      );
      state.value.currentAssessment =
        firstAssessment || state.value.assessments[0]?.assessment_id;

      state.value.assessmentsObject.items = [
        {
          options: state.value.assessments.map((a) => ({
            value: a.assessment_id,
            text: format(new Date(a.assessment_timestamp), "dd MMM yyyy HH:mm"),
          })),
        },
      ];
      state.value.assessmentsObject.values = state.value.currentAssessment;
    });

    return {
      state,
      getScores: computed(() => {
        const assessment = state.value.assessments.find(
          (a) => a.assessment_id === state.value.currentAssessment
        );
        if (assessment) {
          return {
            MMSE: assessment.score_mmse.toString(),
            ADASCog: assessment.score_adascog.toString(),
          };
        }
        return null;
      }),
      getCurrentAssessmentText: computed(() => {
        const assessment = state.value.assessments.find(
          (a) => a.assessment_id === state.value.currentAssessment
        );
        if (assessment) {
          return format(
            new Date(assessment.assessment_timestamp),
            "dd MMM yyyy HH:mm"
          );
        }

        return "";
      }),
    };
  },
});
