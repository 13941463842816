<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="7ili8dtv9a"
        d="M12 1.333h-.667V0H10v1.333H3.333V0H2v1.333h-.667C.6 1.333 0 1.933 0 2.667v10.666c0 .734.6 1.334 1.333 1.334H12c.733 0 1.333-.6 1.333-1.334V2.667c0-.734-.6-1.334-1.333-1.334zm0 12H1.333V6H12v7.333zm0-8.666H1.333v-2H12v2z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0H16V16H0z"
                transform="translate(-137 -445) translate(105 104) translate(32 334) translate(0 7)"
              />
              <path
                d="M2 2L14 2 14 14 2 14z"
                transform="translate(-137 -445) translate(105 104) translate(32 334) translate(0 7)"
              />
              <g
                transform="translate(-137 -445) translate(105 104) translate(32 334) translate(0 7) translate(1.333 .667)"
              >
                <mask id="8odl3q94sb" fill="#fff">
                  <use xlink:href="#7ili8dtv9a" />
                </mask>
                <use fill="#000" fill-rule="nonzero" xlink:href="#7ili8dtv9a" />
                <g fill="#90A4AE" mask="url(#8odl3q94sb)">
                  <path d="M0 0H16V16H0z" transform="translate(-1.333 -.667)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
