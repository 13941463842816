import { Sex } from "./../SitePresetsApi";

export interface SubjectProfile {
  id?: string;
  subject_id?: string;
  site_id?: string;
  site?: string;
  sex_type?: Sex;
  year_of_birth?: number;
  comment: string;
}

export enum CodeStatus {
  Empty = "Empty",
  Generated = "Generated",
  Revoked = "Revoked",
  Entered = "Entered",
  Expired = "Expired",
}

export const canStartAssessment = (status: CodeStatus | undefined) =>
  status !== CodeStatus.Generated && status !== CodeStatus.Entered;

export enum AssessmentStatus {
  Started = "Started",
  Completed = "Completed",
}

export enum AssessmentType {
  HiPAL = "HiPAL",
  PsyCoXx = "PsyCoXx",
}

export interface SubjectItem {
  id: string;
  sex_type: Sex;
  site_id: string;
  site_name: string;

  subject_assessment_info_list: AssessmentItem[];

  subject_id: string;
  year_of_birth: number;
}

export interface AssessmentItem {
  assessment_type: AssessmentType;
  assessments_amount?: number;
  invitation_code_status: CodeStatus;
  invitation_code_value: string;
  last_assessment_id?: string;
  last_assessment_timestamp?: string;
}

export interface AssessmentSearchItem {
  assessment_id: string;
  assessment_timestamp: string;
  score_adascog: number;
  score_mmse: number;
  status: AssessmentStatus;
}

export interface HiPALSubjectItem {
  internal_subject_id: string;
  site_id: string;
  site_name: string;
  sex_type: Sex;
  age: number;
  assessment_id: string;
  mmse_score: number;
  adas_cog_score: number;
  assessment_end_time: string;
}
